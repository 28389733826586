import React from 'react'

import PropTypes from 'prop-types'

import './gallery7.css'

const Gallery7 = (props) => {
  return (
    <div className="gallery7-gallery3 thq-section-padding">
      <div className="gallery7-max-width">
        <div className="gallery7-section-title">
          <h2 className="gallery7-text thq-heading-2">{props.heading1}</h2>
          <span className="gallery7-text1 thq-body-large">
            {props.content1}
          </span>
        </div>
        <div className="gallery7-container">
          <div className="gallery7-content">
            <div className="gallery7-container1">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="gallery7-image1 thq-img-ratio-1-1"
              />
              <img
                alt={props.image2Alt}
                src={props.image2Src}
                className="gallery7-image2 thq-img-ratio-1-1"
              />
            </div>
            <div className="gallery7-container2">
              <img
                alt={props.image3Alt}
                src={props.image3Src}
                className="gallery7-image3 thq-img-ratio-4-3"
              />
              <img
                alt={props.image4Alt}
                src={props.image4Src}
                className="gallery7-image4 thq-img-ratio-1-1"
              />
              <img
                alt={props.image5Alt}
                src={props.image5Src}
                className="gallery7-image5 thq-img-ratio-4-3"
              />
            </div>
            <div className="gallery7-container3">
              <img
                alt={props.image6Alt}
                src={props.image6Src}
                className="gallery7-image6 thq-img-ratio-1-1"
              />
              <img
                alt={props.image7Alt}
                src={props.image7Src}
                className="gallery7-image7 thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery7.defaultProps = {
  heading1: 'Explore Our Diverse Portfolio',
  image4Alt: 'Product Photography',
  content1:
    'Browse through a selection of our photography and videography projects to see the quality and creativity we bring to each assignment.',
  image7Alt: 'Documentary Filming',
  image5Src:
    'https://images.unsplash.com/photo-1557804506-669a67965ba0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  image6Alt: 'Music Video Production',
  image3Src:
    'https://images.unsplash.com/photo-1501236570302-906143a7c9f8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0NXw&ixlib=rb-4.0.3&q=80&w=1080',
  image7Src:
    'https://images.unsplash.com/photo-1494018045030-5ede6cb6faf9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0NXw&ixlib=rb-4.0.3&q=80&w=1080',
  image1Alt: 'Wedding Photography',
  image5Alt: 'Travel Videography',
  image2Alt: 'Corporate Event Coverage',
  image2Src:
    'https://images.unsplash.com/photo-1416339158484-9637228cc908?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  image4Src:
    'https://images.unsplash.com/photo-1605550973696-3732e1af232c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0NXw&ixlib=rb-4.0.3&q=80&w=1080',
  image1Src:
    'https://images.unsplash.com/photo-1517411020229-9cbb75afed2f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  image3Alt: 'Portrait Sessions',
  image6Src:
    'https://images.unsplash.com/photo-1605211419286-3f4f0c3a6013?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NTg0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
}

Gallery7.propTypes = {
  heading1: PropTypes.string,
  image4Alt: PropTypes.string,
  content1: PropTypes.string,
  image7Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image7Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image5Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image4Src: PropTypes.string,
  image1Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image6Src: PropTypes.string,
}

export default Gallery7
