import React from 'react'

import PropTypes from 'prop-types'

import './small-production-text.css'

const SmallProductionText = (props) => {
  return (
    <div className={`small-production-text-container ${props.rootClassName}`}>
      <h1 className="small-production-text-text thq-heading-1">
        {props.smallRedText}
      </h1>
      <h1 className="small-production-text-text1 thq-heading-1">
        {props.productionText}
      </h1>
    </div>
  )
}

SmallProductionText.defaultProps = {
  rootClassName: '',
  productionText: 'production',
  smallRedText: 'small',
}

SmallProductionText.propTypes = {
  rootClassName: PropTypes.string,
  productionText: PropTypes.string,
  smallRedText: PropTypes.string,
}

export default SmallProductionText
