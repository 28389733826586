import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar4 from '../components/navbar4'
import Gallery7 from '../components/gallery7'
import Contact3 from '../components/contact3'
import Footer from '../components/footer'
import './portofolio.css'

const Portofolio = (props) => {
  return (
    <div className="portofolio-container">
      <Helmet>
        <title>portofolio - Small production</title>
        <meta
          property="og:title"
          content="portofolio - Small production"
        />
      </Helmet>
      <Navbar4
        logoSrc="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
        rootClassName="navbar4-root-class-name2"
      ></Navbar4>
      <Gallery7></Gallery7>
      <Contact3
        email1="david@smallprod.ro"
        phone1="+40734285691"
        address1="sibiu str planetei bl 16 ap 18"
        content5="Thanks for stopping by our site!"
      ></Contact3>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Portofolio
