import React from 'react'

import PropTypes from 'prop-types'

import './contact-form7.css'

const ContactForm7 = (props) => {
  return (
    <div className="contact-form7-contact1 thq-section-padding">
      <div className="contact-form7-max-width thq-section-max-width thq-flex-row">
        <div className="contact-form7-section-title thq-flex-column">
          <span className="contact-form7-text thq-body-small">
            {props.content2}
          </span>
          <div className="contact-form7-content">
            <h2 className="thq-heading-2">{props.heading1}</h2>
          </div>
          <div className="contact-form7-content1">
            <div className="contact-form7-row"></div>
            <div className="contact-form7-row1"></div>
            <div className="contact-form7-row2"></div>
          </div>
        </div>
        <form className="contact-form7-form thq-flex-column" id="contact-form" method="POST" action="sendmail.php">
          <div className="contact-form7-container">
            <div className="contact-form7-input">
              <label
                htmlFor="contact-form-7-first-name"
                className="thq-body-small"
              >
                First name
              </label>
              <input
                name="firstName"
                type="text"
                id="contact-form-7-first-name"
                placeholder="First Name"
                className="thq-input"
              />
            </div>
            <div className="contact-form7-input1">
              <label
                htmlFor="contact-form-7-last-name"
                className="thq-body-small"
              >
                Last name
              </label>
              <input
                name="lastName"
                type="text"
                id="contact-form-7-last-name"
                placeholder="Last Name"
                className="thq-input"
              />
            </div>
          </div>
          <div className="contact-form7-container1">
            <div className="contact-form7-input2">
              <label htmlFor="contact-form-7-email" className="thq-body-small">
                Email
              </label>
              <input
                name="email"
                type="email"
                id="contact-form-7-email"
                required="true"
                placeholder="Email"
                className="thq-input"
              />
            </div>
            <div className="contact-form7-input3">
              <label htmlFor="contact-form-7-phone" className="thq-body-small">
                Phone Number
              </label>
              <input
               name="phone"
                type="tel"
                id="contact-form-7-phone"
                placeholder="Phone Number"
                className="thq-input"
              />
            </div>
          </div>
          <div className="contact-form7-container2">
            <div className="contact-form7-input4">
              <label
                htmlFor="contact-form-7-options"
                className="thq-body-small"
              >
                Choose one topic
              </label>
              <select
                id="contact-form-7-options"
                placeholder="Select one"
                className="thq-select"
              >
                <option value="Option 1">Customer service</option>
                <option value="Option 1">short-form content</option>
                <option value="Option 1">podcast</option>
                <option value="Option 2">weddings</option>
                <option value="Option 2">summits/conference</option>
                <option value="Option 2">aerial photo/video</option>
              </select>
            </div>
          </div>
          <div className="contact-form7-container3">
            <div className="contact-form7-input5">
              <label
                htmlFor="contact-form-7-message"
                className="thq-body-small"
              >
                Message
              </label>
              <textarea
                name="message"
                id="contact-form-7-message"
                rows="3"
                placeholder="Enter your message"
                className="thq-input"
              ></textarea>
            </div>
          </div>
          <div className="contact-form7-checkbox">
            <input
              type="checkbox"
              id="contact-form-7-check"
              checked="true"
              required="true"
              className="thq-checkbox"
            />
            <label htmlFor="contact-form-7-check" className="thq-body-small">
              I accept the Terms
            </label>
          </div>
          <button
            type="submit"
            className="contact-form7-button thq-button-filled"
          >
            <span className="thq-body-small">{props.action}</span>
          </button>
        </form>
      </div>
    </div>
  )
}

ContactForm7.defaultProps = {
  content1: "Let's talk",
  action: 'Contact us now',
  heading1: "Let's talk",
  content2: 'Get in touch with us for a consultation or to book our services.',
  email: 'Email',
  phone: 'Phone',
  address: '123 Main Street, City, Country',
}

ContactForm7.propTypes = {
  content1: PropTypes.string,
  action: PropTypes.string,
  heading1: PropTypes.string,
  content2: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
}

export default ContactForm7
