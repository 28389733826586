import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar4 from '../components/navbar4'
import ContactForm7 from '../components/contact-form7'
import Footer from '../components/footer'
import './get-in-touch.css'

const GetInTouch = (props) => {
  return (
    <div id="get in touch" className="get-in-touch-container">
      <Helmet>
        <title>Get-in-touch - Small Production</title>
        <meta
          property="og:title"
          content="Get-in-touch - Small Production"
        />
      </Helmet>
      <Navbar4
        logoSrc="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
        rootClassName="navbar4-root-class-name4"
      ></Navbar4>
      <ContactForm7></ContactForm7>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default GetInTouch
