import React from 'react'

import PropTypes from 'prop-types'

import './gallery2.css'

const Gallery2 = (props) => {
  return (
    <div className="gallery2-gallery3 thq-section-padding">
      <div className="gallery2-max-width thq-section-max-width">
        <div className="gallery2-section-title">
          <h2 className="gallery2-text thq-heading-2">{props.heading1}</h2>
        </div>
        <div className="gallery2-content">
          <div className="gallery2-container">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery2-image1 thq-img-ratio-9-16"
            />
          </div>
          <div className="gallery2-container1">
          <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery2-image2 thq-img-ratio-9-16"
            />
          </div>
          <div className="gallery2-container2">
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery2-image3 thq-img-ratio-9-16"
            />
          </div>
          <div className="gallery2-container3">
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="gallery2-image31 thq-img-ratio-9-16"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery2.defaultProps = {
  image1Alt: 'Nature Photography 1',
  content1:
    'Explore our stunning collection of nature photography showcasing the beauty of the natural world.',
  image4Src:
    '/interview.gif',
  image1Src:
    '/profile.jpg',
  heading1: 'A sneak peek into our large portofolio',
  image2Src:
    '/showcase.gif',
  image2Alt: '',
  image4Alt: 'Interview',
  image3Src:
    '/festival.jpeg',
  image3Alt: 'Concert',
}

Gallery2.propTypes = {
  image1Alt: PropTypes.string,
  content1: PropTypes.string,
  image4Src: PropTypes.string,
  image1Src: PropTypes.string,
  heading1: PropTypes.string,
  image2Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image3Alt: PropTypes.string,
}

export default Gallery2
