import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import LogoText from './logo-text'
import './navbar4.css'

const Navbar4 = (props) => {
  const [isMobileOpen, setIsMobileOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <header className={`navbar4-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar4-navbar-interactive">
        <a href="#home" className="navbar4-link">
          <LogoText
            rootClassName="logo-text-root-class-name"
            className="navbar4-component"
          ></LogoText>
        </a>
        <div data-thq="thq-navbar-nav" className="navbar4-desktop-menu">
          <nav className="navbar4-links">
            <Link to="/" className="navbar4-link1 thq-body-small thq-link">
              {props.link1}
            </Link>
            <Link
              to="/portofolio"
              className="navbar4-link2 thq-body-small thq-link"
            >
              {props.link2}
            </Link>
            {/* <Link to="/about" className="navbar4-link3 thq-body-small thq-link">
              {props.link3}
            </Link> */}
            <a
              href="#contact"
              className="navbar4-link4 thq-body-small thq-link"
            >
              {props.link4}
            </a>
          </nav>
          {/* <div className="navbar4-buttons">
            <Link
              to="/get-in-touch"
              className="navbar4-action2 thq-button-animated thq-button-outline"
            >
              <span className="navbar4-text thq-body-small">
                <span className="">Get in touch</span>
                <br className=""></br>
              </span>
            </Link>
          </div> */}
        </div>
        <div onClick={toggleMenu} className="navbar4-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar4-icon">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
        <div className={isMobileOpen ? "navbar4-mobile-menu thq-show thq-translate-to-default teleport-show" : "navbar4-mobile-menu hide-mobile"}>
          <div className="navbar4-nav">
            <div className="navbar4-top">
              <LogoText
                rootClassName="logo-text-root-class-name1"
                className=""
              ></LogoText>
              <div onClick={toggleMenu} className="navbar4-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar4-icon2">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>
            </div>
            <nav className="navbar4-links1">
              <Link to="/" className="MobileMenu">
                {props.link1}
              </Link>
              <Link to="/portofolio" className="MobileMenu">
                {props.link2}
              </Link>
              {/* <Link to="/about" className="MobileMenu">
                {props.link3}
              </Link> */}
              <a href="#contact" onClick={toggleMenu} className="MobileMenu">
                {props.link4}
              </a>
            </nav>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbar4.defaultProps = {
  rootClassName: '',
  link2: 'Portfolio',
  link5: 'Link5',
  link1: 'Home',
  logoAlt: 'Photography & Videography',
  link4: 'Contact',
  action2: 'Action 2',
  logoSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  action1: 'Action 1',
  link3: 'About',
}

Navbar4.propTypes = {
  rootClassName: PropTypes.string,
  link2: PropTypes.string,
  link5: PropTypes.string,
  link1: PropTypes.string,
  logoAlt: PropTypes.string,
  link4: PropTypes.string,
  action2: PropTypes.string,
  logoSrc: PropTypes.string,
  action1: PropTypes.string,
  link3: PropTypes.string,
}

export default Navbar4
