import React from 'react'

import PropTypes from 'prop-types'

import './team2.css'

const Team2 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="team2-max-width thq-section-max-width">
        <div className="team2-section-title">
          <div className="team2-content">
            <p className="team2-text thq-body-large">{props.content2}</p>
            <h2 className="team2-text01 thq-heading-2">{props.heading1}</h2>
          </div>
        </div>
        <span className="team2-text02 thq-body-small">{props.content1}</span>
        <div className="team2-content1">
          <div className="team2-content2">
            <div className="team2-row">
              <div className="team2-card">
                <img
                  alt={props.member1Alt}
                  src={props.member1Src}
                  className="thq-img-ratio-1-1 team2-placeholder-image"
                />
                <div className="team2-content3">
                  <div className="team2-title">
                    <span className="team2-text03 thq-body-small">
                      {props.member1}
                    </span>
                    <span className="team2-text04 thq-body-small">
                      {props.member1Job}
                    </span>
                  </div>
                  <span className="team2-text05 thq-body-small">
                    {props.member1Content}
                  </span>
                </div>
                <div className="team2-social-icons"></div>
              </div>
              <div className="team2-card1">
                <img
                  alt={props.member2Alt}
                  src={props.member2Src}
                  className="thq-img-ratio-1-1 team2-placeholder-image1"
                />
                <div className="team2-content4">
                  <div className="team2-title1">
                    <span className="team2-text06 thq-body-small">
                      {props.member2}
                    </span>
                    <span className="team2-text07 thq-body-small">
                      {props.member2Job}
                    </span>
                  </div>
                  <span className="team2-text08 thq-body-small">
                    {props.member2Content}
                  </span>
                </div>
                <div className="team2-social-icons1"></div>
              </div>
              <div className="team2-card2">
                <img
                  alt={props.member3Alt}
                  src={props.member3Src}
                  className="thq-img-ratio-1-1 team2-placeholder-image2"
                />
                <div className="team2-content5">
                  <div className="team2-title2">
                    <span className="team2-text09 thq-body-small">
                      {props.member3}
                    </span>
                    <span className="team2-text10 thq-body-small">
                      {props.member3Job}
                    </span>
                  </div>
                  <span className="team2-text11 thq-body-small">
                    {props.member3Content}
                  </span>
                </div>
                <div className="team2-social-icons2"></div>
              </div>
            </div>
            <div className="team2-row1"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Team2.defaultProps = {
  content2: '',
  member1Src:
    '/profileDavid.jpeg',
  content1:
    'Our team at SmallProduction is a group of talented and creative individuals dedicated to delivering high-quality photography and videography services.',
  member4Alt: 'Image of Sarah Brown, Creative Director at SmallProduction',
  member4Job: 'Creative Director',
  member4: 'Sarah Brown',
  member5Content:
    'Michael oversees the production process from start to finish, ensuring that every project is executed seamlessly. His dedication to excellence is unmatched.',
  member2: 'Stefan Hodoroaba',
  member1Job: 'Founder & video editor',
  member4Content:
    'Sarah leads our team with her visionary ideas and strategic direction. Her expertise in the industry drives our creativity and success.',
  member1Content:
    'David is an editor with 5+ years of video editing experience, knowing how to edit simple quick 30 second films to 2 hour long podcasts.',
  content3: '',
  member5Job: 'Producer',
  member4Src:
    'https://images.unsplash.com/photo-1494959764136-6be9eb3c261e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NjMwNnw&ixlib=rb-4.0.3&q=80&w=1080',
  member2Src:
    '/profileStefan.jpg',
  heading2: '',
  member6Job: 'Marketing Manager',
  member5: 'Michael Lee',
  member6: 'Emily White',
  member3Content:
    'Meet Miriam, our photography maestro who skillfully captures moments and transforms them into stunning visual stories. With her keen eye for detail, she ensures that every photograph surpasses your expectations.',
  member3Src:
    'https://images.unsplash.com/photo-1485981133625-f1a03c887f0a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NjMwNXw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Meet Our Team',
  member6Content:
    'Emily handles our marketing strategies and client relations with professionalism and enthusiasm. Her passion for promoting our work helps us reach new heights.',
  member6Src:
    'https://images.unsplash.com/photo-1523464862212-d6631d073194?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NjMwNnw&ixlib=rb-4.0.3&q=80&w=1080',
  member2Content:
    'Stefan is a talented individual with 4+ years in html and web design, and is ready to take your next website challenge to reality',
  member6Alt: 'Image of Emily White, Marketing Manager at SmallProduction',
  member3Job: 'Photographer',
  actionContent: 'Open positions',
  member3: 'Miriam Groza',
  member2Alt: 'Image of Jane Smith, Videographer at SmallProduction',
  member5Src:
    'https://images.unsplash.com/photo-1639628735078-ed2f038a193e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODY0NjMwNnw&ixlib=rb-4.0.3&q=80&w=1080',
  member3Alt: 'Image of Alex Johnson, Editor at SmallProduction',
  member5Alt: 'Image of Michael Lee, Producer at SmallProduction',
  member2Job: 'Web developer',
  member1Alt: 'Image of John Doe, Photographer at SmallProduction',
  member1: 'David Grigorita',
}

Team2.propTypes = {
  content2: PropTypes.string,
  member1Src: PropTypes.string,
  content1: PropTypes.string,
  member4Alt: PropTypes.string,
  member4Job: PropTypes.string,
  member4: PropTypes.string,
  member5Content: PropTypes.string,
  member2: PropTypes.string,
  member1Job: PropTypes.string,
  member4Content: PropTypes.string,
  member1Content: PropTypes.string,
  content3: PropTypes.string,
  member5Job: PropTypes.string,
  member4Src: PropTypes.string,
  member2Src: PropTypes.string,
  heading2: PropTypes.string,
  member6Job: PropTypes.string,
  member5: PropTypes.string,
  member6: PropTypes.string,
  member3Content: PropTypes.string,
  member3Src: PropTypes.string,
  heading1: PropTypes.string,
  member6Content: PropTypes.string,
  member6Src: PropTypes.string,
  member2Content: PropTypes.string,
  member6Alt: PropTypes.string,
  member3Job: PropTypes.string,
  actionContent: PropTypes.string,
  member3: PropTypes.string,
  member2Alt: PropTypes.string,
  member5Src: PropTypes.string,
  member3Alt: PropTypes.string,
  member5Alt: PropTypes.string,
  member2Job: PropTypes.string,
  member1Alt: PropTypes.string,
  member1: PropTypes.string,
}

export default Team2
