import React from 'react'

import PropTypes from 'prop-types'

import './logos11.css'

const Logos11 = (props) => {
  return (
    <div className="logos11-container thq-section-padding">
      <div className="logos11-max-width thq-section-max-width">
        <h2 className="logos11-text thq-heading-2">{props.heading1}</h2>
        <div className="logos11-container1 thq-grid-6">
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="logos11-logo2 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="logos11-logo1 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo6Alt}
            src={props.logo6Src}
            className="logos11-logo6 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="logos11-logo3 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="logos11-logo4 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt}
            src={props.logo5Src}
            className="logos11-logo5 thq-img-ratio-16-9"
          />
        </div>
        <span className="thq-body-small">{props.text}</span>
      </div>
    </div>
  )
}

Logos11.defaultProps = {
  logo6Alt: 'Logo6',
  heading1: 'Collaborations with industry leaders to establish credibility',
  logo2Alt: 'Videography Portfolio Logo',
  logo1Src: '/000%20logo%20final-1400w.png',
  logo6Src: '/logo-bikeathon-2-1400w.png',
  logo1Alt: 'Photography Portfolio Logo',
  logo4Alt: 'Logo4',
  logo3Alt: 'Logo3',
  logo4Src: '/logo%20albastru%20cu%20text%20transparent%20-1400w.png',
  logo5Alt: 'Logo5',
  logo2Src: '/cropped-heartbeats-main-logo-1400w.png',
  logo5Src: '/logo%20cs%202023-1400w.png',
  logo3Src: '/logo%2020.03.02%20png%20no%20bg-1400w.png',
  text: 'Follow us on social media for more updates and behind-the-scenes content.',
}

Logos11.propTypes = {
  logo6Alt: PropTypes.string,
  heading1: PropTypes.string,
  logo2Alt: PropTypes.string,
  logo1Src: PropTypes.string,
  logo6Src: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo4Alt: PropTypes.string,
  logo3Alt: PropTypes.string,
  logo4Src: PropTypes.string,
  logo5Alt: PropTypes.string,
  logo2Src: PropTypes.string,
  logo5Src: PropTypes.string,
  logo3Src: PropTypes.string,
  text: PropTypes.string,
}

export default Logos11
