import React from 'react'

import PropTypes from 'prop-types'

import SmallProductionText from './small-production-text'
import './hero-title.css'

const HeroTitle = (props) => {
  return (
    <div className="hero-title-container">
      <h1 className="hero-title-text thq-heading-1">{props.heading1}</h1>
      <SmallProductionText rootClassName="small-production-text-root-class-name"></SmallProductionText>
    </div>
  )
}

HeroTitle.defaultProps = {
  heading1: 'welcome to',
}

HeroTitle.propTypes = {
  heading1: PropTypes.string,
}

export default HeroTitle
