import React from 'react'

import PropTypes from 'prop-types'

import './reviews.css'
import ReviewCard from './reviews-card'
import ReviewCard2 from './reviews-card2'

const Reviews = (props) => {
  return (
    <div className="reviews-container thq-section-padding">
      <div className="reviews-max-width thq-section-max-width">
        <h2 className="reviews-text thq-heading-2">{props.heading1}</h2>
        <div className="review-cards">
          <ReviewCard/>
          <ReviewCard2/>
        </div>
      </div>
    </div>
  )
}

Reviews.defaultProps = {
  logo6Alt: 'Logo6',
  heading1: 'What people say about us',
  logo2Alt: 'Videography Portfolio Logo',
  logo1Src: '/000%20logo%20final-1400w.png',
  logo6Src: '/logo-bikeathon-2-1400w.png',
  logo1Alt: 'Photography Portfolio Logo',
  logo4Alt: 'Logo4',
  logo3Alt: 'Logo3',
  logo4Src: '/logo%20albastru%20cu%20text%20transparent%20-1400w.png',
  logo5Alt: 'Logo5',
  logo2Src: '/cropped-heartbeats-main-logo-1400w.png',
  logo5Src: '/logo%20cs%202023-1400w.png',
  logo3Src: '/logo%2020.03.02%20png%20no%20bg-1400w.png',
  text: 'Follow us on social media for more updates and behind-the-scenes content.',
}

Reviews.propTypes = {
  logo6Alt: PropTypes.string,
  heading1: PropTypes.string,
  logo2Alt: PropTypes.string,
  logo1Src: PropTypes.string,
  logo6Src: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo4Alt: PropTypes.string,
  logo3Alt: PropTypes.string,
  logo4Src: PropTypes.string,
  logo5Alt: PropTypes.string,
  logo2Src: PropTypes.string,
  logo5Src: PropTypes.string,
  logo3Src: PropTypes.string,
  text: PropTypes.string,
}

export default Reviews
