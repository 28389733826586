import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import About from './views/about'
import Home from './views/home'
import GetInTouch from './views/get-in-touch'
import Portofolio from './views/portofolio'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        {/* <Route component={About} exact path="/about" /> */}
        <Route component={Home} exact path="/" />
        <Route component={GetInTouch} exact path="/get-in-touch" />
        <Route component={Portofolio} exact path="/portofolio" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
