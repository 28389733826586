import React from 'react'

import PropTypes from 'prop-types'

import './logo-text.css'

const LogoText = (props) => {
  return (
    <div className={`logo-text-container ${props.rootClassName} `}>
      <h1 className="logo-text-text">{props.smallRedText}</h1>
      <h1 className="logo-text-text1">{props.productionText}</h1>
    </div>
  )
}

LogoText.defaultProps = {
  smallRedText: 'small',
  productionText: 'production',
  rootClassName: '',
}

LogoText.propTypes = {
  smallRedText: PropTypes.string,
  productionText: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default LogoText
