import React from 'react'

import PropTypes from 'prop-types'
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/fontawesome-free-solid'

import './reviews-card2.css'
fontawesome.library.add(faStar);

const ReviewCard2 = (props) => {
  return (
    <div className="reviews-card-container">
      <div className="star-row">
        <div className='stars'>
        <FontAwesomeIcon className={"checked"} icon="fa-star" />
        <FontAwesomeIcon className={"checked"} icon="fa-star checked" />
        <FontAwesomeIcon className={"checked"} icon="fa-solid fa-star checked" />
        <FontAwesomeIcon className={"checked"} icon="fa-solid fa-star checked" />
        <FontAwesomeIcon className={"checked"} icon="fa-solid fa-star checked" />
        </div>
        <div>24 Jul 2024</div>
      </div>
      <div className='review-text'>
      <p>Da, un uauu de încântare că acele momente au fost surprinse prin fotografie. Ochiul lui David ne permite să retrăim momentele unice de la Cupa Făgărașului #10, oferind fiecărui participant o doză de energie. Mulțumesc, David, pentru cadrele care ne dau șansa să retrăim această competiție de înot pentru copii. Fiecare poză are ceva deosebit, exprimând naturalețe și stil. Îmi place foarte mult cum ai încadrat reperele tematice.</p>
      </div>
      <div className='user'>
        <p>Aurelian</p>
      </div>
    </div>
  )
}

ReviewCard2.defaultProps = {
  logo6Alt: 'Logo6',
  heading1: 'What people say about us',
  logo2Alt: 'Videography Portfolio Logo',
  logo1Src: '/000%20logo%20final-1400w.png',
  logo6Src: '/logo-bikeathon-2-1400w.png',
  logo1Alt: 'Photography Portfolio Logo',
  logo4Alt: 'Logo4',
  logo3Alt: 'Logo3',
  logo4Src: '/logo%20albastru%20cu%20text%20transparent%20-1400w.png',
  logo5Alt: 'Logo5',
  logo2Src: '/cropped-heartbeats-main-logo-1400w.png',
  logo5Src: '/logo%20cs%202023-1400w.png',
  logo3Src: '/logo%2020.03.02%20png%20no%20bg-1400w.png',
  text: 'Follow us on social media for more updates and behind-the-scenes content.',
}

ReviewCard2.propTypes = {
  logo6Alt: PropTypes.string,
  heading1: PropTypes.string,
  logo2Alt: PropTypes.string,
  logo1Src: PropTypes.string,
  logo6Src: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo4Alt: PropTypes.string,
  logo3Alt: PropTypes.string,
  logo4Src: PropTypes.string,
  logo5Alt: PropTypes.string,
  logo2Src: PropTypes.string,
  logo5Src: PropTypes.string,
  logo3Src: PropTypes.string,
  text: PropTypes.string,
}

export default ReviewCard2
