import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar4 from '../components/navbar4'
import Hero9 from '../components/hero9'
import Gallery2 from '../components/gallery2'
import Logos11 from '../components/logos11'
import Reviews from '../components/reviews'
import Contact3 from '../components/contact3'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Small production</title>
        <meta property="og:title" content="Small Production" />
      </Helmet>
      <Navbar4
        logoSrc="https://play.teleporthq.io/projects/trustworthy-creamy-shrew-2mr6mk/preview/TQ_tfO8CkYOrC"
        rootClassName="navbar4-root-class-name"
      ></Navbar4>
      <Hero9
        heading1="welcome to smallproduction"
        rootClassName="hero9-root-class-name"
      ></Hero9>
      <Gallery2></Gallery2>
      <Logos11></Logos11>
      <Reviews></Reviews>
      <Contact3
        email1="david@smallprod.ro"
        phone1="+40734285691"
        address1="sibiu str planetei bl 16 ap 18"
        content5="Thanks for stopping by our site!"
      ></Contact3>
      <Footer></Footer>
    </div>
  )
}

export default Home
